import React, { useState, useEffect } from "react";

import { loadStripe } from "@stripe/stripe-js";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import StarIcon from "@material-ui/icons/StarBorder";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import {
  API_SESSION,
  STRIPE_PUBLISHABLE_KEY,
  SUBSCRIPTION_PLANS,
} from "../consts";

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
}));

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const Message = ({ message }) => (
  <Container maxWidth="md" component="main">
    <Typography variant="h5" align="center" color="textPrimary" gutterBottom>
      {message}
    </Typography>
  </Container>
);

export const SubscriptionPlans = () => {
  const [message, setMessage] = useState("");

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setMessage(
        "Order placed! You will receive an email confirmation shortly."
      );
    }

    if (query.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  const handleClick = async (planId) => {
    const stripe = await stripePromise;

    if (!stripe) {
      // handle error
      return;
    }

    console.log("API_SESSION", API_SESSION);

    const response = await fetch(API_SESSION, {
      body: JSON.stringify({
        priceId: planId,
      }),
      method: "POST",
    });

    const responseBody = await response.json();

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: responseBody.session.id,
    });

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  };

  const classes = useStyles();

  return message ? (
    <Message message={message} />
  ) : (
    <Grid container spacing={5} alignItems="flex-end">
      {SUBSCRIPTION_PLANS.map((tier) => (
        // Enterprise card is full width at sm breakpoint
        <Grid
          item
          key={tier.title}
          xs={12}
          sm={tier.title === "Enterprise" ? 12 : 6}
          md={4}
        >
          <Card>
            <CardHeader
              title={tier.title}
              subheader={tier.subheader}
              titleTypographyProps={{ align: "center" }}
              subheaderTypographyProps={{ align: "center" }}
              action={tier.title === "Pro" ? <StarIcon /> : null}
              className={classes.cardHeader}
            />
            <CardContent>
              <div className={classes.cardPricing}>
                <Typography component="h2" variant="h3" color="textPrimary">
                  ${tier.price}
                </Typography>
                <Typography variant="h6" color="textSecondary">
                  /mo
                </Typography>
              </div>
              <ul>
                {tier.description.map((line) => (
                  <Typography
                    component="li"
                    variant="subtitle1"
                    align="center"
                    key={line}
                  >
                    {line}
                  </Typography>
                ))}
              </ul>
            </CardContent>
            <CardActions>
              <Button
                onClick={() => handleClick(tier.id)}
                fullWidth
                variant={tier.buttonVariant}
                color="primary"
              >
                {tier.buttonText}
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
