export const COMPANY_EMAIL = "info@easystreetdev.com";

export const STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

export const YOUR_DOMAIN =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DOMAIN_URL
    : "https://easystreetdev.com/";
export const API_SESSION = `${YOUR_DOMAIN}api/session`;
export const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY;

const STARTER_300 = process.env.REACT_APP_STARTER_300;
const PROFESSIONAL_1000 = process.env.REACT_APP_PROFESSIONAL_1000;
const ENTERPRISE_3000 = process.env.REACT_APP_ENTERPRISE_3000;

export const SUBSCRIPTION_PLANS = [
  {
    id: STARTER_300,
    title: "Starter",
    price: "75",
    description: [
      "300 emails / month",
      "Automatic email updates",
      "Email support",
    ],
    buttonText: "Get started",
    buttonVariant: "outlined",
  },
  {
    id: PROFESSIONAL_1000,
    title: "Professional",
    subheader: "Most popular",
    price: "100",
    description: [
      "1000 emails / month",
      "Automatic email updates",
      "Email support",
    ],
    buttonText: "Get started",
    buttonVariant: "contained",
  },
  {
    id: ENTERPRISE_3000,
    title: "Enterprise",
    price: "175",
    description: [
      "3000 emails / month",
      "Automatic email updates",
      "Email & Text support",
    ],
    buttonText: "Get started",
    buttonVariant: "outlined",
  },
];
