import * as React from "react";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";

import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import ListAltRoundedIcon from "@material-ui/icons/ListAltRounded";
import DraftsRoundedIcon from "@material-ui/icons/DraftsRounded";

import { SubscriptionPlans } from "../components";

const useStyles = makeStyles((theme) => ({
  bottomDivider: {
    margin: theme.spacing(2, 33, 2),
  },
  detailsContainer: {
    fontSize: "20px",
    fontWeight: "600",
    marginBottom: "15px",
  },
  detailsText: {
    margin: theme.spacing(0, 0, 2),
  },
  divider: {
    margin: theme.spacing(8, 0, 0),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  iconWrapper: {
    backgroundColor: "#176BCC",
    height: "65px",
    width: "65px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "100px",
    marginBottom: "15px",
  },
  spacerWrapper: {
    // marginBottom: "10px",
  },
  listContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginTop: "15px",
  },
  listItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: "20px",
    fontWeight: "800",
  },
  pricingContent: {
    padding: theme.spacing(8, 0, 6),
  },
}));

export const Pricing = () => {
  const classes = useStyles();
  return (
    <>
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography
          component="h3"
          variant="h4"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          StreetEasy Rental Leads Integration
        </Typography>
      </Container>
      <Container maxWidth="md" component="main">
        <SubscriptionPlans></SubscriptionPlans>
      </Container>
      <div className={classes.divider}>
        <Divider></Divider>
      </div>
      <Container
        maxWidth="lg"
        align="center"
        className={classes.pricingContent}
      >
        <div>
          <Typography
            component="h4"
            variant="h4"
            align="center"
            color="textPrimary"
          >
            How it Works
          </Typography>
          <Container
            maxWidth="sm"
            align="center"
            className={classes.listContainer}
          >
            <div className={classes.listItem}>
              <div className={classes.iconWrapper}>
                <ArrowForwardRoundedIcon style={{ color: "#FFFFFF" }} />
              </div>
              <div>Forward emails</div>
            </div>
            <div className={classes.spacerWrapper}>
              <ChevronRightRoundedIcon />
            </div>

            <div className={classes.listItem}>
              <div className={classes.iconWrapper}>
                <DraftsRoundedIcon style={{ color: "#FFFFFF" }} />
              </div>
              <div>Define Lead Data</div>
            </div>
            <div className={classes.spacerWrapper}>
              <ChevronRightRoundedIcon />
            </div>

            <div className={classes.listItem}>
              <div className={classes.iconWrapper}>
                <ListAltRoundedIcon style={{ color: "#FFFFFF" }} />
              </div>
              <div>Receive Leads</div>
            </div>
          </Container>
        </div>
        <div className={classes.bottomDivider}>
          <Divider></Divider>
        </div>
        <Container maxWidth="sm" align="center">
          <div align="center" className={classes.detailsContainer}>
            Details
          </div>
          <div className={classes.detailsText} align="center">
            All StreetEasy rental lead emails, with subject lines containing
            either of the below two phrases will be automatically ingested:{" "}
            <b>StreetEasy Inquiries</b> and <b>Agent Inquiries</b>.
          </div>
          <div align="center">
            The extracted data will be added (in real time) to a privately
            shared Google Spreadsheet.
          </div>
        </Container>
      </Container>
    </>
  );
};
