import * as React from "react";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: (props) => props.color || "#176BCC",
    height: (props) => props.height || "40vh",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  sectionContainer: {
    color: "#FFFFFF",
  },
}));

const SectionBlock = (props) => {
  const classes = useStyles(props);

  return (
    <Container
      maxWidth={false}
      disableGutters={true}
      className={classes.container}
    >
      {props.children}
    </Container>
  );
};

export const Home = () => {
  const classes = useStyles();
  return (
    <>
      <SectionBlock height="60vh">
        <Container
          maxWidth="false"
          component="main"
          className={classes.sectionContainer}
        >
          <Typography gutterBottom component="h1" variant="h2" align="center">
            Automate Your StreetEasy Rental Leads
          </Typography>
          <Typography component="h4" variant="h4" align="center">
            You provide the emails, we provide the data
          </Typography>
        </Container>
      </SectionBlock>
    </>
  );
};
