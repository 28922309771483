import React, { PureComponent } from "react";
import {
  Button,
  Container,
  FormControl,
  FormGroup,
  FormLabel,
  TextField,
} from "@material-ui/core";

const formWrapper = {
  display: "flex",
  width: "50%",
  flexDirection: "column",
  margin: "auto",
};

const submitButtonStyle = { margin: "auto" };

const formControlStyle = {
  margin: "10px",
  minWidth: "120px",
};
export class Contact extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      message: "",
      submitting: false,
      submitted: false,
    };
  }

  updateField = (e) => {
    const newState = {
      ...this.state,
      [e.target.name]: e.target.value,
    };

    this.setState({
      ...newState,
    });
  };

  handleResponse = (status, msg) => {
    if (status === 200) {
      console.log("submitted", status, msg);
      this.setState({ submitted: true });
    } else {
      console.log("submitted", status, msg);
    }
  };

  validateFormFields = () => {
    const { firstName, lastName, email, message } = this.state;
    return (
      firstName !== "" && lastName !== "" && email !== "" && message !== ""
    );
  };

  handleOnSubmit = async (e) => {
    e.preventDefault();
    console.log("sending email!", this.state);
    const { firstName, lastName, email, message } = this.state;

    // set state of submitting
    this.setState({ submitting: true });
    const res = await fetch("/api/contact-admin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ firstName, lastName, email, message }),
    });
    const text = await res.text();
    this.handleResponse(res.status, text);
  };

  render() {
    const { submitting } = this.state;
    const formReady = this.validateFormFields();

    return (
      <Container maxWidth="lg">
        <div style={{ textAlign: "center" }}>
          <h1>Contact Us</h1>
        </div>

        {submitting ? (
          <Container maxWidth="lg" style={{ textAlign: "center" }}>
            <div>
              <h3>Your message was sent successfully! </h3>
              <h4> Please check your email for additional information. </h4>
            </div>
          </Container>
        ) : (
          <Container style={formWrapper} maxWidth="lg">
            <FormGroup row>
              <FormControl style={formControlStyle} required>
                <FormLabel id="firstNameLabel">First Name</FormLabel>
                <TextField
                  onChange={this.updateField}
                  name="firstName"
                ></TextField>
              </FormControl>
              <FormControl style={formControlStyle} required>
                <FormLabel id="lastNameLabel">Last Name</FormLabel>
                <TextField
                  onChange={this.updateField}
                  name="lastName"
                ></TextField>
              </FormControl>
            </FormGroup>
            <FormGroup row>
              <FormControl style={formControlStyle} required>
                <FormLabel id="emailLabel">Email</FormLabel>
                <TextField onChange={this.updateField} name="email"></TextField>
              </FormControl>
            </FormGroup>
            <FormGroup row>
              <FormControl
                style={{ ...formControlStyle, minWidth: "90%" }}
                required
              >
                <FormLabel id="messageLabel">Message</FormLabel>
                <TextField
                  onChange={this.updateField}
                  name="message"
                  multiline
                  rows="4"
                  variant="outlined"
                ></TextField>
              </FormControl>
            </FormGroup>
            <div style={submitButtonStyle}>
              <Button
                onClick={this.handleOnSubmit}
                variant="contained"
                disabled={!formReady}
              >
                Send Message
              </Button>
            </div>
          </Container>
        )}
      </Container>
    );
  }
}
