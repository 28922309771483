import * as React from "react";

import { Footer, Header } from "./components/";
import { Contact, Home, Pricing, Terms, Privacy } from "./pages/";
import CssBaseline from "@material-ui/core/CssBaseline";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

export default function BasicExample() {
  return (
    <Router>
      <CssBaseline />
      <Header></Header>
      <div>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/pricing">
            <Pricing />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/terms">
            <Terms />
          </Route>
          <Route path="/privacy">
            <Privacy />
          </Route>
        </Switch>
      </div>
      <Footer></Footer>
    </Router>
  );
}
