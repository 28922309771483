import * as React from "react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
}));

export const Header = () => {
  const classes = useStyles();

  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      className={classes.appBar}
    >
      <Toolbar className={classes.toolbar}>
        <Typography
          variant="h6"
          color="inherit"
          noWrap
          className={classes.toolbarTitle}
        >
          <Link underline="none" component={RouterLink} to="/">
            Easy Street Development
          </Link>
        </Typography>
        <nav>
          <Link
            component={RouterLink}
            to="/pricing"
            variant="button"
            color="textPrimary"
            href="#"
            className={classes.link}
          >
            Pricing
          </Link>
          <Link
            component={RouterLink}
            to="/contact"
            variant="button"
            color="textPrimary"
            href="#"
            className={classes.link}
          >
            Contact Us
          </Link>
        </nav>
      </Toolbar>
    </AppBar>
  );
};
