import React from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

const FOOTERS = [
  {
    title: "Contact Us",
    to: "/contact",
  },
  {
    title: "Pricing",
    to: "/pricing",
  },
  {
    title: "Privacy policy",
    to: "/privacy",
  },
  { title: "Terms of use", to: "/terms" },
];

export const Footer = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="md" component="footer" className={classes.footer}>
      <Grid container justify="space-evenly">
        {FOOTERS.map((footer) => (
          <Grid item xs={2} key={footer.title}>
            <Typography variant="h6" color="textPrimary" gutterBottom>
              <Link
                component={RouterLink}
                to={footer.to}
                variant="subtitle1"
                color="textSecondary"
              >
                {footer.title}
              </Link>
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
};

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link to="/" component={RouterLink} color="inherit">
        Easy Street Development
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
